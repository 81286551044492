.carousel{
    position: absolute;
    z-index: -1;
    min-width: 100%;
    min-height: 85vh;
    top: 0;
 }

 @media screen and (max-width: 1300px) {
    .carousel {
        min-width: 1300px;
    }
 }
 
 .carousel_inner{
    width: 100%;
    overflow: hidden;
 }
 
 .carousel_inner::after{
    content: "";
    display: block;
    clear: both;
 }
 
 .carousel_item{
    position: relative;
    float: left;
    display: none;
    width: 100%;
    margin-right: -100%;
 }
 
 /* slide effect */
 .carousel_item__active,
 .carousel_item__pos_prev,
 .carousel_item__pos_next{
    display: block;
 }
 
 .carousel_item__pos_prev{
    left: -100%;
 }
 
 .carousel_item__pos_next{
    left: 100%;
 }
 
 .carousel_item__prev{
    transform: translateX(100%);
    transition: transform .5s ease-in-out;
 }
 
 .carousel_item__next{
    transform: translateX(-100%);
    transition: transform .5s ease-in-out;
 }
 
 /* fade effect */
 .carousel__fade .carousel_item__pos_prev,
 .carousel__fade .carousel_item__pos_next{
    left: 0;
    opacity: 0;
 }
 
 .carousel__fade .carousel_item__prev,
 .carousel__fade .carousel_item__next{
    transform: none;
    opacity: 1;
    transition: opacity .5s ease-in-out;
 }
 
 .carousel__fade .carousel_item__active.carousel_item__prev,
 .carousel__fade .carousel_item__active.carousel_item__next{
    opacity: 0;
 }
 
 /* carousel */
 .carousel_img{
    display: block;
    width: 100%;
    min-width: 100%;
    min-height: 85vh;
 }

 .carousel_indicator{
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    gap: .5rem;
    z-index: 1;
 }
 
 .carousel_dot{
    display: block;
    padding: .25rem;
    background-color: rgba(255, 255, 255, .25);
    border: none;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color .5s ease-in-out;
 }
 
 .carousel_dot__active{
    background-color: lightskyblue;
    cursor: default;
    pointer-events: none;
 }
 
 .carousel_control{
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
 }
 
 .carousel_button{
    display: block;
    padding-inline: 1rem;
    font-family: "Raleway", sans-serif;
    font-size: 1.375rem;
    background-color: transparent;
    color: rgba(255, 255, 255, .25);
    border: none;
    cursor: pointer;
    transition: color .25s;
    z-index: 1;
 }
 
 .carousel_button:hover{
    color: rgba(135, 206, 250, .75);
 }