.eachcard {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    margin: 20px 0 0;
    h1 {
        font-size: 3.2rem;
        position: relative;
        padding: 0 0 5px 0;
        &:after {
            content: "";
            background: #0E2F2E;
            position: absolute;
            bottom: 0;
            left: 15%;
            height: 5px;
            width: 70%;
            border-radius: 10px;
          }
    }
}

.eachcard__main {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: center;
    align-items: center;
    margin: 60px 0;
}

.eachcard__menu {
    display: flex;
    flex-flow: column;
    width: 450px;
    background-color: #0E2F2E;
    height: 520px;
    padding: 30px 40px;
    margin-right: 50px;
}

.eachcard__menu-item {
    display: flex;
    flex-flow: column;
    margin: 10px 0;
    span {
        color: #ececec;
        font-size: 1.45rem;
        font-weight: lighter;
    }
}

.eachcard__img {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    height: max-content;
    img {
        height: 180px;
    }
}

.eachcard__copywrite {
    display: flex;
    justify-content: center;
    width: 95%;
    padding-top: 25px;
    border-top: #0E2F2E 1px solid;
    margin: 30px 0;
    h4 {
        color: #0E2F2E;
    }
}

@media screen and (max-width: 1400px) {
    .eachcard {
        h1 {
            font-size: 2.8rem;
            padding: 0;
            &:after {
                height: 4px;
              }
        }
    }
    .eachcard__menu {
        width: 440px;
        height: 510px;
        margin-right: 40px;
    }
    
    .eachcard__menu-item {
        span {
            font-size: 1.35rem;
        }
        p {
            font-size: 0.6rem;
        }
    }
    
    .eachcard__img {
        img {
            height: 170px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .eachcard {
        h1 {
            font-size: 2.5rem;
        }
    }
    .eachcard__menu {
        width: 380px;
        height: 460px;
        padding: 30px 40px;
        margin-right: 30px;
    }
    
    .eachcard__menu-item {
        span {
            font-size: 1.2rem;
        }
    }
    
    .eachcard__img {
        img {
            height: 150px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .eachcard {
        h1 {
            font-size: 2.3rem;
            &:after {
                height: 3px;
              }
        }
    }
    .eachcard__menu {
        width: 340px;
        height: 420px;
        margin-right: 10px;
    }
    
    .eachcard__menu-item {
        span {
            font-size: 1rem;
        }
    }
    
    .eachcard__img {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
        img {
            height: 130px;
        }
    }
    
    .eachcard__copywrite {
        h4 {
            font-size: 0.8rem;
        }
    }
}

@media screen and (max-width: 850px) {
    .eachcard {
        h1 {
            margin-top: 20px;
        }
    }

    .eachcard__main {
        grid-template-columns: 1fr;
        margin: 40px 0;
        row-gap: 30px;
    }

    .eachcard__menu {
        width: 350px;
        height: 420px;
        margin-right: 0;
    }
    
    .eachcard__menu-item {
        span {
            font-size: 1rem;
        }
    }
    
    .eachcard__img {
        img {
            height: 140px;
        }
    }
    
    .eachcard__copywrite {
        h4 {
            font-size: 0.75em;
        }
    }
}

@media screen and (max-width: 500px) {
    .eachcard {
        h1 {
            font-size: 2.2rem;
        }
    }

    .eachcard__menu {
        width: 310px;
        height: 400px;
    }
    
    .eachcard__menu-item {
        span {
            font-size: 0.95rem;
        }
    }
    
    .eachcard__img {
        gap: 10px;
        img {
            height: 115px;
        }
    }
    .eachcard__copywrite {
        h4 {
            font-size: 0.7rem;
        }
    }   
}

@media screen and (max-width: 360px) {
    .eachcard {
        h1 {
            font-size: 2rem;
        }
    }

    .eachcard__menu {
        width: 280px;
        height: 380px;
        padding: 30px;
    }
    
    .eachcard__menu-item {
        span {
            font-size: 0.9rem;
        }
    }
    
    .eachcard__img {
        img {
            height: 105px;
        }
    }
}