@import url('https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Be Vietnam Pro', sans-serif;
  font-weight: bold;
  color: #0E2F2E;
  transition: all ease 0.3s;
}

html {
  scroll-behavior: smooth;
}

.App {
  display: flex;
  flex-flow: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  position: relative;
  background: #ececec;
}

a {
  color: inherit;
  text-decoration: none;
}