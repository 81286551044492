.navbar {
    display: flex;  
    justify-content: space-between;
    width: 100%;
    height: 100px;
    padding: 0 30px;
    .logo {
        height: 100px; 

    }
}

.navbar__right {
    button {
        height: 45px;
        width: 190px;
        background-color: #ECECEC;
        border-radius: 5px;
        border: 1px solid #ececec;
        cursor: pointer;
        transition: 0.4s ease all;

        &:hover {
            background-color: #ececec00;
            color: #ececec;
        }
    }
}

.navbar__right-green {
    button {
        background-color: #0E2F2E;
        border: 1px solid #0E2F2E;
        color: #ECECEC;
        font-weight: lighter    ;

        &:hover {
            background-color: #0e2f2e00;
            color: #0E2F2E;
            font-weight: bold;
        }
    }
}

.navbar__links {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    img {
        height: 28px;
        margin: 0 10px;
        transition: 0.3s ease all;
        cursor: pointer;

        &:hover {
            transform: translateX(5px);
        }
    }
}

@media screen and (max-width: 1200px) {
    .navbar {
        .logo {
            height: 80px; 
    
        }
    }
    
    .navbar__right {
        button {
            height: 38px;
        }
    }
    
    .navbar__links {
        img {
            height: 24px;
        }
    }
}

@media screen and (max-width: 1000px) {
    
    .navbar {
        .logo {
            height: 80px; 
    
        }
    }
    
    .navbar__right {
        button {
            height: 38px;
        }
    }
}

@media screen and (max-width: 600px) {
    .navbar {
        padding: 0 10px;
        .logo {
            height: 70px; 
    
        }
    }
    
    .navbar__right {
        button {
            height: 35px;
            width: 150px;
            font-size: 0.7rem;
        }
    }
}

@media screen and (max-width: 500px) {
    .navbar__right {
        margin-top: 5px;
        button {
            height: 32px;
            width: 130px;
            font-size: 0.6rem;
        }
    }
    
    .navbar__links {
        img {
            height: 20px;
            margin: 0 5px;
        }
    }
}

@media screen and (max-width: 400px) {
    .navbar {
        .logo {
            height: 65px; 
        }
    }
    
    .navbar__right {
        button {
            height: 30px;
            width: 120px;
        }
    }
}