.homepage {
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 800px;
    width: 100%;
    padding: 25px 0;
    position: relative;
    overflow: hidden;
    background: none;
    z-index: 1;
    h1 {
        margin: 160px 120px;
        font-size: 4rem;
        width: 470px;
        color: #ecececda;
        align-self: flex-start;
        background-color: #0e2f2e9d;
        padding: 30px 50px;
        border-radius: 10px;
    }
}

@media screen and (max-width: 1400px) {
    .homepage {
        h1 {
            margin: 160px 100px;
            font-size: 4rem;
            width: 470px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .homepage {
        height: 700px;
        h1 {
            margin: 160px 70px;
            font-size: 3.4rem;
            width: 400px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .homepage {
        h1 {
            font-size: 3rem;
            width: 350px;
            padding: 25px 30px;
        }
    }
}

@media screen and (max-width: 600px) {
    .homepage {
        padding: 25px 0;
        height: 700px;
    }
}

@media screen and (max-width: 500px) {
    .homepage {
        height: 620px;
        text-align: center;
        padding: 15px 0;
        h1 {
            align-self: center;
            margin: 160px 0;
            font-size: 2.5rem;
            width: 320px;
            padding: 25px 30px;
        }
    }
}

@media screen and (max-width: 400px) {
    .homepage {
        h1 {
            font-size: 2.2rem;
            width: 290px;
            padding: 25px 30px;
        }
    }
}

@media screen and (max-width: 350px) {
    .homepage {
        h1 {
            font-size: 2rem;
            width: 270px;
        }
    }
}