.games {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    position: relative;
    width: 1100px;
    margin: 100px 0 180px;
}

.games__info {
    display: flex;
    flex-flow: column;
    width: 650px;
    background-color: #0E2F2E;
    height: 400px;
    border-radius: 10px;
    padding: 40px 50px;
    margin-left: 400px;
    h2 {
        color: #ececec;
        font-size: 2.6rem;
        position: relative;
        padding: 0 0 12px 0;

        &:after {
            content: "";
            background: #ececec;
            position: absolute;
            bottom: 0;
            left: 18%;
            height: 3px;
            width: 70%;
            border-radius: 10px;
          }
    }
    p {
        color: #ececec;
        font-weight: lighter;
        font-size: 1.1rem;
        margin: 20px 10px 0 80px;
    }
}

.games__pic {
    position: absolute;
    top: 115px;
    left:  62px;
    img {
        height: 340px;
    }
}

@media screen and (max-width: 1400px) {
    .games {
        width: 900px;
    }
    
    .games__info {
        width: 600px;
        height: 400px;
        margin-left: 300px;
        h2 {
            font-size: 2.3rem;
        }
        p {
            font-size: 1rem;
            margin: 20px 10px 0 95px;
        }
    }
    
    .games__pic {
        top: 115px;
        left:  0;
        img {
            height: 310px;
        }
    }
}

@media screen and (max-width: 1200px) {
    .games {
        width: 800px;
        margin: 100px 0;
    }
    
    .games__info {
        width: 500px;
        height: 370px;
        h2 {
            padding: 0 0 8px;
            font-size: 1.8rem;
            &:after {
                height: 2px;
              }
        }
        p {
            font-size: 0.9rem;
        }
    }
    
    .games__pic {
        left:  20px;
        img {
            height: 290px;
        }
    }
}

@media screen and (max-width: 1000px) {
    .games {
        width: 550px;
        margin: 100px 0 250px;
    }
    
    .games__info {
        width: 100%;
        height: 320px;
        margin-left: 0;
        p {
            margin: 15px 0;
        }
    }
    
    .games__pic {
        top: 270px;
        left:  125px;
        img {
            height: 230px;
        }
    }
}

@media screen and (max-width: 800px) {
    .games {
        width: 480px;
        margin: 50px 0 160px;
    }
    
    .games__info {
        width: 100%;
        height: 300px;
        h2 {
            font-size: 1.6rem;
        }
        p {
            font-size: 0.8rem;
            margin: 15px 0;
        }
    }
    
    .games__pic {
        top: 250px;
        left:  95px;
        img {
            height: 200px;
        }
    }
}

@media screen and (max-width: 600px) {
    .games {
        width: 400px;
    }
    
    .games__info {
        width: 100%;
        height: 320px;
        padding: 40px;
        h2 {
            font-size: 1.5rem;
        }
    }
    
    .games__pic {
        top: 270px;
        left:  75px;
        img {
            height: 180px;
        }
    }
}

@media screen and (max-width: 500px) {
    .games {
        width: 350px;
        margin: 60px 0 120px;
    }
    
    .games__info {
        width: 100%;
        height: 310px;
        padding: 30px 10px;
        h2 {
            font-size: 1.3rem;
        }
        p {
            font-size: 0.7rem;
            margin: 10px 0;
            padding: 0 30px;
        }
    }
    
    .games__pic {
        top: 240px;
        left:  63px;
        img {
            height: 160px;
        }
    }
}

@media screen and (max-width: 400px) {
    .games {
        width: 320px;
    }
    
    .games__info {
        p {
            padding: 0 20px;
        }
    }
    
    .games__pic {
        top: 250px;
        left:  55px;
        img {
            height: 150px;
        }
    }
}

@media screen and (max-width: 360px) {
    .games {
        width: 300px;
    }
    
    .games__pic {
        top: 260px;
        left:  52px;
        img {
            height: 140px;
        }
    }
}