.contact {
    display: flex;
    width: 1100px;
    justify-content: center;
    text-align: center;
    margin: 0 0 100px;
    padding: 60px 0 0;
}

.contact__infos {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;
    padding: 20px 30px 0;
    h1 {
        font-size: 3rem;
        position: relative;
        padding: 0 0 5px 0;
        margin-bottom: 30px;

        &:after {
            content: "";
            background: #0E2F2E;
            position: absolute;
            bottom: 0;
            left: 15%;
            height: 5px;
            width: 70%;
            border-radius: 10px;
          } 
    }
    button {
        margin: 20px 0 30px;
        height: 42px;
        width: 220px;
        background-color: #0E2F2E;
        color: #ececec;
        font-weight: lighter;
        border-radius: 5px;
        border: 2px solid #0E2F2E;
        cursor: pointer;
        transition: 0.4s ease all;

        &:hover {
            background-color: #0e2f2e00;
            color: #0E2F2E;
            font-weight: bold
        }
    }
}

.contact__info {
    display: flex;
    justify-content: center;
    margin: 8px 0;
    img {
        height: 25px;
    }
    span {
        font-size: 1.2rem;
        margin-left: 5px;
    }
}

.contact__pointer {
    cursor: pointer;
}

.contact__links {
    display: flex;
    img {
        height: 32px;
        margin: 0 10px;
        transition: 0.3s ease all;
        cursor: pointer;

        &:hover {
            transform: translateX(5px);
        }
    }
}

.contact__timings {
    display: flex;
    flex-flow: column;
    width: 100%;
    align-items: center;
}

.contact__timing-box {
    display: flex;
    flex-flow: column;
    align-items: center;
    background-color: #0E2F2E;
    width: 390px;
    height: 430px;
    padding: 40px 20px;
    h2 {
        color: #ececec;
        font-size: 2rem;
        font-weight: lighter;
        margin-bottom: 20px;
    }
}

.contact__timing-day {
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin: 10px 0;

    span {
        color: #ececec;
        font-weight: lighter;
        font-size: 0.9rem;
        &:first-child {
            width: 90px;
        }
        &:last-child {
            margin-left: 10px;
        }
    }
}

@media screen and (max-width: 1400px) {
    .contact {
        width: 900px;
    }
    
    .contact__infos {
        h1 {
            font-size: 2.6rem;
            margin-bottom: 20px;
            &:after {
                height: 3px;
              } 
        }
        button {
            height: 40px;
            width: 210px;
        }
    }
    
    .contact__info {
        img {
            height: 22px;
        }
        span {
            font-size: 1.1rem;
            margin-left: 5px;
        }
    }
    
    .contact__links {
        img {
            height: 30px;
            margin: 0 10px;
        }
    }
    
    .contact__timing-box {
        width: 360px;
        height: 420px;
        padding: 40px 20px;
        h2 {
            font-size: 1.9rem;
            margin-bottom: 20px;
        }
    }
    
    .contact__timing-day {
        width: 90%;
        margin: 10px 0;
    
        span {
            font-size: 0.85rem;
            &:first-child {
                width: 90px;
            }
            &:last-child {
                margin-left: 10px;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .contact {
        width: 800px;
    }
    
    .contact__infos {
        h1 {
            font-size: 2.4rem;
            margin-bottom: 20px;
            &:after {
                height: 3px;
              } 
        }
        button {
            height: 38px;
            width: 200px;
            font-size: 0.7rem;
            margin-top: 20px;
        }
    }
    
    .contact__info {
        img {
            height: 20px;
        }
        span {
            font-size: 1rem;
            margin-left: 5px;
        }
    }
    
    .contact__links {
        img {
            height: 26px;
            margin: 0 10px;
        }
    }
    
    .contact__timing-box {
        width: 330px;
        height: 400px;
        padding: 40px 20px;
        h2 {
            font-size: 1.7rem;
            margin-bottom: 20px;
        }
    }
    
    .contact__timing-day {
        width: 90%;
        margin: 10px 0;
    
        span {
            font-size: 0.75rem;
            &:first-child {
                width: 70px;
            }
            &:last-child {
                margin-left: 10px;
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .contact {
        margin: 0 0 80px;
        width: 400px;
        flex-flow: column ;
    }
    
    .contact__infos {
        margin-bottom: 80px;
        h1 {
            font-size: 2.2rem;
            margin-bottom: 20px;
            &:after {
                height: 2px;
              } 
        }
        button {
            height: 35px;
            width: 190px;
            font-size: 0.65rem;
            margin-top: 20px;
        }
    }
    
    .contact__info {
        img {
            height: 17px;
        }
        span {
            font-size: 0.9rem;
            margin-left: 5px;
        }
    }
    
    .contact__links {
        img {
            height: 23px;
            margin: 0 10px;
        }
    }
    
    .contact__timing-box {
        width: 310px;
        height: 380px;
        padding: 40px 20px;
        h2 {
            font-size: 1.5rem;
            margin-bottom: 20px;
        }
    }
    
    .contact__timing-day {
        width: 90%;
        margin: 10px 0;
    
        span {
            font-size: 0.65rem;
            &:first-child {
                width: 60px;
            }
        }
    }
}

@media screen and (max-width: 600px) {
    .contact {
        width: 300px;
        margin: 0 0 20px;
    }
    
    .contact__infos {
        margin-bottom: 80px;
        h1 {
            font-size: 2rem;
            margin-bottom: 20px;
            &:after {
                height: 2px;
              } 
        }
        button {
            height: 32px;
            width: 170px;
            font-size: 0.6rem;
            margin-top: 20px;
        }
    }
    
    .contact__info {
        img {
            height: 15px;
        }
        span {
            font-size: 0.8rem;
            margin-left: 5px;
        }
    }
    
    .contact__links {
        img {
            height: 21px;
            margin: 0 10px;
        }
    }
    
    .contact__timing-box {
        width: 280px;
        height: 350px;
        padding: 40px 20px;
        h2 {
            font-size: 1.3rem;
            margin-bottom: 10px;
        }
    }
    
    .contact__timing-day {
        width: 90%;
        margin: 10px 0;
    
        span {
            font-size: 0.6rem;
            &:first-child {
                width: 60px;
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .contact {
        width: 250px;
    }
    
    .contact__infos {
        margin-bottom: 80px;
        padding: 20px 0 0;
        h1 {
            font-size: 1.8rem;
            margin-bottom: 20px;
            &:after {
                height: 2px;
              } 
        }
        button {
            height: 30px;
            width: 160px;
            font-size: 0.5rem;
            margin-top: 20px;
        }
    }
    
    .contact__info {
        img {
            height: 15px;
        }
        span {
            font-size: 0.75rem;
            margin-left: 5px;
        }
    }
    
    .contact__links {
        img {
            height: 20px;
            margin: 0 10px;
        }
    }
    
    .contact__timing-box {
        width: 250px;
        height: 330px;
        padding: 40px 20px;
        h2 {
            font-size: 1.2rem;
            margin-bottom: 10px;
        }
    }
    
    .contact__timing-day {
        width: 90%;
        margin: 10px 0;
    
        span {
            font-size: 0.55rem;
            &:first-child {
                width: 50px;
            }
        }
    }
}

